/* Built In Imports */
import { useContext } from 'react';

/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import WrapLayout from '@Layout/WrapLayout';
import Breadcrum from '@components/Navigation/Breadcrum';
import CenterCommonUI from '@components/UI/Center';
import LeftNavCommonUI from '@components/UI/Common/LeftNavCommonUI';
import HeadComponent from '@components/Utility/HeadComponent';
import USChatBot from '@components/Utility/USChatBot';
import config from '@config';
import { AuthContext } from '@store/auth-context';
import { useRouter } from 'next/router';

/* Services */
import { getLocale } from '@components/Utility/Shared/SharedService';
import { fetchDetails, getLandingPaths } from 'services/centerService';
import {
  fetchFooterData,
  fetchHeaderMenuData,
  fetchHeaderTopMenuData,
} from 'services/commonService';

/* Styles */

/**
 *
 * @param {string} region - Current User Region
 * @param {string} language - Current User Language
 * @param {string} pageData - Health Landing Page Data
 */
const CenterSubSectionDetail = ({ region, language, pageData }) => {
  const authContext = useContext(AuthContext);

  const router = useRouter();
  const centerUrl = router.asPath.includes('/living-isha');
  
  // useEffect(() => {
  //   authContext.RegionAndLang(region, language);
  // }, [authContext, region, language]);

  // consoleLog('page', pageData);

  return (
    <WrapLayout pad={0}>
      <HeadComponent
        data={pageData?._seoMetaTags || pageData?.seoFields}
        availableLocals={pageData.availableLocales}
      >
        {' '}
      </HeadComponent>
      <Breadcrum
        page={pageData.breadcrumbs}
        alsoin={pageData.alsoin}
        url=""
        region={authContext.region}
        lang={language}
        pageConfig={pageData?.pageConfig}
      />

      <Box as="section" className="page-wrap container-article" p="0">
        {/* <Flex
          as="section"
          className="container-article"
        > */}
        <CenterCommonUI
          pageBody={pageData.body}
          region={authContext.region}
          lang={language}
        />
        <LeftNavCommonUI
          pageBody={pageData.bodyLeftNavSections}
          region={authContext.region}
          lang={language}
          isLeftMenu={true}
        />
        <CenterCommonUI
          pageBody={pageData.bodyBottomSection}
          region={authContext.region}
          lang={language}
        />
      </Box>
      {centerUrl && <USChatBot region={authContext.region}></USChatBot>}
    </WrapLayout>
  );
};

export const getStaticPaths = async () => {
  const centerLandingPaths = config.USE_LOCAL_URL
    ? [
        `/en/center/isha-institute-inner-sciences/living-isha`,
        `/en/center/yogashala/yoga-for-wellness`,
        `/en/center/isha-institute-inner-sciences/application-process-for-isha-kamala`,
        `/en/center/isha-institute-inner-sciences/application-process-for-kshetragna-accommodation`,
      ]
    : await getLandingPaths('L2', '/center/', [
        '/center/consecrated-spaces/',
        '/center/local-center/',
        '/center/isha-research-center/',
      ]);

  // consoleLog('center', centerLandingPaths);
  return {
    paths: centerLandingPaths,
    fallback: false,
  };
};

export const getStaticProps = async ({
  preview,
  previewData,
  params: { region, center, subSection },
  req,
}) => {
  const language = region;
  const headerTopMenuData = await fetchHeaderTopMenuData(
    getLocale('', language),
    'isotopmenu'
  );
  const headerMenuData = await fetchHeaderMenuData(
    getLocale('', language),
    'iso_non_regional_menu'
  );
  const footerData = await fetchFooterData(
    getLocale('', language),
    'isofooter'
  );
  const pageData = await fetchDetails('', language, `${center}/${subSection}`, {
    preview,
    data: previewData,
  });

  return {
    props: {
      headerTopMenuData: headerTopMenuData?.data || null,
      headerMenuData: headerMenuData?.data || null,
      region: '',
      language: language,
      footerData: footerData?.data || null,
      pageData: pageData || null,
      isPreview: preview ? true : false,
    },
  };
};

export default CenterSubSectionDetail;
